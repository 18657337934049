import Videos from "../pages/videos";
import { graphql } from "gatsby";

export default Videos;

export const pageQuery = graphql`
query TemplateVideosPage($skip: Int) {
  # Get videos
  videos: allVideosYaml(
    sort: { order: DESC, fields: [createdDate, title]},
    limit: 10,
    skip: $skip
  ) {
    totalCount
    edges {
      node {
        id
        title
        createdDate(formatString: "DD MMMM, YYYY")
        service
        yamlId
        url
      }
    }
  }
}
`;
